<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow
    backdrop no-header right @change="val => $emit('update:is-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t('Add New') : $t('Update') }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <b-card-text>{{ $t('Image') }} {{ $t('All') }} {{ images.length }}
                {{ $t('Image') }}</b-card-text>
            </b-col>
            <b-col v-for="(item, index) in images" :key="index" md="4" cols="6">
              <b-img fluid-grow :src="item" rounded class="mr-1 mb-1 imgs-show" height="120" />
              <b-button variant="danger" class="btn-remove" @click="removeImage(index)">
                {{ $t('Delete') }} ({{ index + 1 }})
              </b-button>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <!-- media -->
                <b-media no-body>
                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
                      @click="$refs.refInputEl.$el.click()">
                      {{ $t('Upload') }}
                    </b-button>
                    <b-form-file ref="refInputEl" accept=".webp, .jpeg, .jpg, .png, .gif" :hidden="true" plain
                      @change="inputImageRenderer" />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm"
                      class="mb-75 mr-75" @click="resetImage">
                      {{ $t('Reset') }}
                    </b-button>
                    <!--/ reset -->
                    <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG.
                      {{ $t('Max size of') }} 300KB</b-card-text>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-card-text>{{ $t('Name') }}</b-card-text>
            </b-col>
            <b-col v-for="(item, index) in language" :key="index + 'name'" cols="12">
              <!-- firstName -->
              <!-- firstName -->
              <b-form-group :label="item.name" :label-for="item.name + 'name'">
                <validation-provider #default="{ errors }" :name="item.name + 'name'" :vid="item.name + 'name'">
                  <b-form-input v-if="name[index]" :id="item.name + 'name'" :name="item.name + 'name'"
                    :value="name[index]['value']" :state="errors.length > 0 ? false : null" :placeholder="item.name"
                    @input="inputLanguage($event, item.lang, index, 'name')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-card-text>{{ $t('Detail') }}</b-card-text>
            </b-col>
            <b-col v-for="(item, index) in language" :key="index + 'detail'" cols="12">
              <!-- firstName -->
              <!-- firstName -->
              <b-form-group :label="item.name" :label-for="item.name + 'detail'">
                <validation-provider #default="{ errors }" :name="item.name + 'detail'" :vid="item.name + 'detail'">
                  <b-form-textarea v-if="detail[index]" :id="item.name + 'detail'" :name="item.name + 'detail'"
                    :value="detail[index]['value']" :state="errors.length > 0 ? false : null" :placeholder="item.name"
                    @input="inputLanguage($event, item.lang, index, 'detail')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group :label="$t('Service')" label-for="register-service">
                <validation-provider #default="{ errors }" name="service" vid="service" rules="required">
                  <v-select id="register-service" v-model="serviceId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="respDataService"
                    :reduce="respDataService => respDataService._id" class="select-size-lg" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="$t('Color Name')" label-for="register-colorName">
                <validation-provider #default="{ errors }" name="colorName" vid="colorName" rules="required">
                  <v-select id="register-colorName" v-model="colorName"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="OptionColorName"
                    :reduce="OptionColorName => OptionColorName.value" class="select-size-lg" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- Agency -->
              <b-form-group :label="$t('Agency')" label-for="register-agency">
                <validation-provider #default="{ errors }" name="Agency" vid="agency" :rules="''">
                  <v-select v-model="agency" chips multiple size="sm" label="name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="agencyData"
                    :reduce="agencyData => agencyData._id" :clearable="true" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- Agency -->
              <b-form-group :label="$t('Department')" label-for="register-department">
                <validation-provider #default="{ errors }" name="Department" vid="department" :rules="''">
                  <v-select v-model="department" chips multiple size="sm" label="name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="departmentData"
                    :reduce="departmentData => departmentData._id" :clearable="true" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-checkbox v-model="config.called" class="custom-control-primary">
                {{ $t('Called') }}
              </b-form-checkbox>
            </b-col>
            <b-col cols="6">
              <b-form-checkbox v-model="config.goFind" class="custom-control-primary">
                {{ $t('Go Find') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" @click="validationForm()">
              {{ $t('Submit') }}
            </b-button>
            <b-button type="button" variant="outline-secondary" @click="hide">
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar,
  BButton,
  BFormFile,
  BMedia,
  // BMediaAside,
  BMediaBody,
  // BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BRow,
  BCol,
  BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    // BMediaAside,
    BMediaBody,
    // BLink,
    BImg,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    storeModuleNameService: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    isSelectedServiceType: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => { },
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      images: [],
      name: '',
      detail: [],
      serviceId: '',
      colorName: 'info',
      config: {
        called: false,
        goFind: false,
      },
      position: 0,
      agency: [],
      department: [],
      required,
    }
  },
  computed: {
    OptionColorName() {
      return [
        { name: this.$t('Danger'), value: 'danger' },
        { name: this.$t('Primary'), value: 'primary' },
        { name: this.$t('Warning'), value: 'warning' },
        { name: this.$t('Success'), value: 'success' },
        { name: this.$t('Info'), value: 'info' },
      ]
    },
    respDataService() {
      // return store.state[this.storeModuleNameService].respData != null ? store.state[this.storeModuleNameService].respData.data : []
      if (store.state[this.storeModuleNameService].respData !== null) {
        return this.formatData(
          store.state[this.storeModuleNameService].respData.data,
        )
      }
      return []
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
    agencyData() {
      return store.state.agency.respData != null
        ? store.state.agency.respData.data.map(e => {
          const indexLang = e.name.findIndex(
            name => name.lang === this.$i18n.locale,
          )
          if (indexLang > -1) {
            return {
              _id: e._id,
              name: e.name[indexLang].value,
            }
          }
          return {
            _id: e._id,
            name: e.name[0].value,
          }
        })
        : []
    },
    departmentData() {
      return store.state.department.respData != null
        ? store.state.department.respData.data.map(e => {
          const indexLang = e.name.findIndex(
            name => name.lang === this.$i18n.locale,
          )
          if (indexLang > -1) {
            return {
              _id: e._id,
              name: e.name[indexLang].value,
            }
          }
          return {
            _id: e._id,
            name: e.name[0].value,
          }
        })
        : []
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
          if (this.detail.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.detail.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.detail.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
        } else {
          const {
            _id,
            images,
            name,
            detail,
            serviceId,
            colorName,
            config,
            agency,
            department,
            position,
          } = this.data
          this.dataId = _id
          this.images = images
          this.name = name
          this.detail = detail
          this.position = position
          this.agency = agency
          this.department = department
          this.serviceId = serviceId._id
          if (colorName !== null) {
            this.colorName = colorName
          }
          this.config = config
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.name.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.name[index].lang,
              )
              if (indexLanguage < 0) {
                this.name.splice(index, 1)
              }
            }
          }
          if (this.detail.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.detail.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.detail.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.detail.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.detail[index].lang,
              )
              if (indexLanguage < 0) {
                this.detail.splice(index, 1)
              }
            }
          }
        }
      }
    },
  },
  methods: {
    formatData(data) {
      // จัดรูปแบบข้อมูล

      return data.map(e => {
        const indexLang = e.name.findIndex(
          index => index.lang === this.$i18n.locale,
        )
        if (indexLang > -1) {
          return {
            _id: e._id,
            name: e.name[indexLang].value,
          }
        }
        return {
          _id: e._id,
          name: e.name[0].value,
        }
      })
    },
    inputLanguage(input, lang, index, type) {
      console.log(input, lang, index)
      if (type === 'name') {
        if (this.name[index].lang === lang) {
          this.name[index].value = input
        }
        console.log(this.name)
      } else if (type === 'detail') {
        if (this.detail[index].lang === lang) {
          this.detail[index].value = input
        }
        console.log(this.detail)
      }
    },
    removeImage(index) {
      this.images.splice(index, 1)
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t(
                'or',
              )} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.images.push(e.target.result)
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.images = []
    },
    initValues() {
      this.dataId = null
      this.images = []
      this.name = []
      this.detail = []
      this.serviceId = ''
      this.colorName = 'info'
      this.config = {
        called: false,
        goFind: false,
      }
      this.position = 0
      this.agency = []
      this.department = []

      // this.$refs.refInputEl.reset()
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            images: this.images,
            name: this.name,
            detail: this.detail,
            serviceId: this.serviceId,
            colorName: this.colorName,
            config: this.config,
            position: this.position,
            agency: this.agency,
            department: this.department,
            isSelectedServiceType: this.isSelectedServiceType,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                console.log('fetch Success : ', result)
                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.btn-remove {
  position: absolute;
  top: 20%;
  left: 30%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 12px 16px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
}

.imgs-show {
  // width: 100%;
  height: 100px;
}
</style>
