<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <sidebar
      :store-module-name="STORE_MODULE_NAME"
      :store-module-name-service="STORE_MODULE_NAME_SERVICE"
      :is-sidebar-active.sync="isSidebarActive"
      :is-selected-service-type="isSelectedServiceType"
      :show.sync="show"
      :data="sidebarData"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >

      <b-row>
      <b-col md="12">
        <b-card>
          <b-col
            md="12"
            class="b-col-custom"
          >
            <div class="d-flex align-items-center justify-content-start">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex mr-2"
                @click="openMenuSort()"
              >
                <span class="align-middle">{{ $t('Sort') }}</span>
              </b-button>
            </div>
          </b-col>

        </b-card>

      </b-col>
      <b-col md="4">
        <b-card
          class="chat-widget"
          no-body
        >
          <b-card-header>
            <div class="d-flex align-items-center">
              <b-avatar
                v-if="selected1!==null && selected1.images.length>0"
                size="34"
                :src="selected1.images[0]"
                class="mr-50"
              />
              <b-avatar
                v-else
                size="34"
                :src="require('@/assets/images/blank/no_image.png')"
                class="mr-50"
              />
              <b-col>
                <h5
                  v-if="selected1"
                  class="mb-0"
                >
                  {{ selected1.name[0].value }}
                </h5>
                <h5
                  v-else
                  class="mb-0"
                >
                  {{ $t('Not Selected') }}
                </h5>
                <h5
                  class="mb-0"
                >
                  {{ $t('Service type level') }} : 1
                </h5>
              </b-col>

            </div>
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex"
                @click="sidebarAddNew(0)"
              >
                <feather-icon
                  icon="PlusIcon"
                />
                <!-- <span class="align-middle">Add Users</span> -->
              </b-button>
            </div>
          </b-card-header>
          <section class="chat-app-window">
            <vue-perfect-scrollbar
              ref="refChatLogPS1"
              class="user-chats scroll-area"
              :options="perfectScrollbarSettings"
            >
              <!-- Filters -->
              <b-list-group class="list-group-filters">
                <b-form-input
                  v-model="searchQuery1"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')+'...'"
                />
                <b-list-group-item disabled>
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Image') }}
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >

                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('RefId') }}
                      </p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >

                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Name') }}
                      </p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                      class="b-col-custom"
                    >
                      <div class="d-flex align-items-center justify-content-end">
                        <p class="align-text-bottom line-height-1">
                          {{ this.$t('ACTION') }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item
                  v-for="filter in serviceType1"
                  :key="filter._id"
                  :active="activeList1(filter)"
                  @click="selectServiceType(filter , 0)"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-avatar
                        v-if="filter.images.length>0"
                        size="34"
                        :src="filter.images[0]"
                        class="mr-50"
                      />
                      <b-avatar
                        v-else
                        size="34"
                        :src="require('@/assets/images/blank/no_image.png')"
                        class="mr-50"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p
                        class="align-text-bottom line-height-1"
                      >{{ filter.refId }}</p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >

                      <p
                        v-for="(item,index) in filter.name"
                        :key="index"
                        class="align-text-bottom line-height-1"
                      >{{ (item.lang).toUpperCase() }} : {{ item.value }}</p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                      class="b-col-custom"
                    >
                      <div class="d-flex align-items-center justify-content-end">

                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item @click="sidebarEdit(filter, 0)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="deleteData( filter._id )">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>

            </vue-perfect-scrollbar>
          </section>
        </b-card>

      </b-col>
      <b-col md="4">
        <b-card
          class="chat-widget"
          no-body
        >
          <b-card-header>
            <div class="d-flex align-items-center">
              <b-avatar
                v-if="selected2!==null && selected2.images.length>0"
                size="34"
                :src="selected2.images[0]"
                class="mr-50"
              />
              <b-avatar
                v-else
                size="34"
                :src="require('@/assets/images/blank/no_image.png')"
                class="mr-50"
              />
              <b-col>
                <h5
                  v-if="selected2"
                  class="mb-0"
                >
                  {{ selected2.name[0].value }}
                </h5>
                <h5
                  v-else
                  class="mb-0"
                >
                  {{ $t('Not Selected') }}
                </h5>
                <h5
                  class="mb-0"
                >
                  {{ $t('Service type level') }} : 2
                </h5>
              </b-col>
            </div>
            <div
              v-if="selected1"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex"
                @click="sidebarAddNew(1)"
              >
                <feather-icon
                  icon="PlusIcon"
                />
                <!-- <span class="align-middle">Add Users</span> -->
              </b-button>
            </div>
          </b-card-header>
          <section class="chat-app-window">
            <vue-perfect-scrollbar
              ref="refChatLogPS1"
              class="user-chats scroll-area"
              :options="perfectScrollbarSettings"
            >

              <!-- Filters -->
              <b-list-group class="list-group-filters">
                <b-form-input
                  v-model="searchQuery2"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')+'...'"
                />
                <b-list-group-item disabled>
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Image') }}
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >

                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('RefId') }}
                      </p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >

                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Name') }}
                      </p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                      class="b-col-custom"
                    >
                      <div class="d-flex align-items-center justify-content-end">
                        <p class="align-text-bottom line-height-1">
                          {{ this.$t('ACTION') }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item
                  v-for="filter in serviceType2"
                  :key="filter._id"
                  :active="activeList2(filter)"
                  @click="selectServiceType(filter , 1)"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-avatar
                        v-if="filter.images.length>0"
                        size="34"
                        :src="filter.images[0]"
                        class="mr-50"
                      />
                      <b-avatar
                        v-else
                        size="34"
                        :src="require('@/assets/images/blank/no_image.png')"
                        class="mr-50"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p
                        class="align-text-bottom line-height-1"
                      >{{ filter.refId }}</p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >

                      <p
                        v-for="(item,index) in filter.name"
                        :key="index"
                        class="align-text-bottom line-height-1"
                      >{{ (item.lang).toUpperCase() }} : {{ item.value }}</p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                      class="b-col-custom"
                    >
                      <div class="d-flex align-items-center justify-content-end">

                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item @click="sidebarEdit(filter, 1)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="deleteData( filter._id )">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>

            </vue-perfect-scrollbar>
          </section>
        </b-card>

      </b-col>
      <b-col md="4">
        <b-card
          class="chat-widget"
          no-body
        >
          <b-card-header>
            <div class="d-flex align-items-center justify-content-start">
              <b-avatar
                v-if="selected3!==null&& selected3.images.length>0"
                size="34"
                :src="selected3.images[0]"
                class="mr-50"
              />
              <b-avatar
                v-else
                size="34"
                :src="require('@/assets/images/blank/no_image.png')"
                class="mr-50"
              />
              <b-col>
                <h5
                  v-if="selected3"
                  class="mb-0"
                >
                  {{ selected3.name[0].value }}
                </h5>
                <h5
                  v-else
                  class="mb-0"
                >
                  {{ $t('Not Selected') }}
                </h5>
                <h5
                  class="mb-0"
                >
                  {{ $t('Service type level') }} : 3
                </h5>
              </b-col>
            </div>
            <div
              v-if="selected2"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex"
                @click="sidebarAddNew(2)"
              >
                <feather-icon
                  icon="PlusIcon"
                />
                <!-- <span class="align-middle">Add Users</span> -->
              </b-button>
            </div>
          </b-card-header>
          <section class="chat-app-window">
            <vue-perfect-scrollbar
              ref="refChatLogPS1"
              class="user-chats scroll-area"
              :options="perfectScrollbarSettings"
            >
              <!-- Filters -->
              <b-list-group class="list-group-filters">
                <b-form-input
                  v-model="searchQuery3"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')+'...'"
                />
                <b-list-group-item disabled>
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Image') }}
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >

                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('RefId') }}
                      </p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >

                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Name') }}
                      </p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                      class="b-col-custom"
                    >
                      <div class="d-flex align-items-center justify-content-end">
                        <p class="align-text-bottom line-height-1">
                          {{ this.$t('ACTION') }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item
                  v-for="filter in serviceType3"
                  :key="filter._id"
                  :active="activeList3(filter)"
                  @click="selectServiceType(filter , 2)"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-avatar
                        v-if="filter.image"
                        size="34"
                        :src="filter.image"
                        class="mr-50"
                      />
                      <b-avatar
                        v-else
                        size="34"
                        :src="require('@/assets/images/blank/no_image.png')"
                        class="mr-50"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p
                        class="align-text-bottom line-height-1"
                      >{{ filter.refId }}</p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >

                      <p
                        v-for="(item,index) in filter.name"
                        :key="index"
                        class="align-text-bottom line-height-1"
                      >{{ (item.lang).toUpperCase() }} : {{ item.value }}</p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                      class="b-col-custom"
                    >
                      <div class="d-flex align-items-center justify-content-end">

                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item @click="sidebarEdit(filter ,2)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="deleteData( filter._id )">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>

            </vue-perfect-scrollbar>
          </section>
        </b-card>

      </b-col>
    </b-row>
  
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BImg,
  BBadge,
  BAvatar,
  BFormInput, BButton,
  BTable, BPagination, BDropdown, BOverlay,
  BFormCheckbox,
  BDropdownItem,
  BCardHeader,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/hero/serviceType'
import storeModuleService from '@/store/services/hero/service'
import storeModuleAgency from '@/store/services/hero/agency'
import storeModuleDepartment from '@/store/services/hero/department'
import debounce from 'debounce'
import Sidebar from './DataSidebar.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

const STORE_MODULE_NAME = 'serviceType'
const STORE_MODULE_NAME_SERVICE = 'service'
const STORE_MODULE_NAME_AGENCY = 'agency'
const STORE_MODULE_NAME_DEPARTMENT = 'department'

export default {
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BCardHeader,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BOverlay,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    Sidebar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage') ? localStorage.getItem('itemsPerPage') : 50,
      isSidebarActive: false,
      isSelectedServiceType: null,
      searchQuery1: '',
      searchQuery2: '',
      searchQuery3: '',
      selected1: null,
      selected2: null,
      selected3: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 10,
        wheelPropagation: false,
      },
      sidebarData: {},
      show: false,
      perPageOptions,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'image', label: this.$t('Image'), sortable: false },
        { key: 'name', label: this.$t('Name'), sortable: false },
        { key: 'detail', label: this.$t('Detail'), sortable: false },
        { key: 'serviceId', label: this.$t('Service'), sortable: false },
        { key: 'colorName', label: this.$t('Color Name'), sortable: false },
        { key: 'show', label: this.$t('Show'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.data : []
    },
    serviceType1() {
      const regex = new RegExp(this.searchQuery1, 'i')
      const result = this.respData.filter(p => p.position === 0 && (regex.test(p.refId) || regex.test(p.name.map(n => n.value))))
      return result || []
    },
    serviceType2() {
      let result = []
      const regex = new RegExp(this.searchQuery2, 'i')

      if (this.selected1) {
        result = this.respData.filter(p => p.previousServiceTypeId === this.selected1._id && (regex.test(p.refId) || regex.test(p.name.map(n => n.value))))
      }
      return result || []
    },
    serviceType3() {
      let result = []
      const regex = new RegExp(this.searchQuery3, 'i')

      if (this.selected2) {
        result = this.respData.filter(p => p.previousServiceTypeId === this.selected2._id && (regex.test(p.refId) || regex.test(p.name.map(n => n.value))))
      }
      return result || []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.max : 0
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_SERVICE)) store.registerModule(STORE_MODULE_NAME_SERVICE, storeModuleService)
    if (!store.hasModule(STORE_MODULE_NAME_AGENCY)) store.registerModule(STORE_MODULE_NAME_AGENCY, storeModuleAgency)
    if (!store.hasModule(STORE_MODULE_NAME_DEPARTMENT)) store.registerModule(STORE_MODULE_NAME_DEPARTMENT, storeModuleDepartment)

    this.getAgency()
    this.getDepartment()

    const obj = {
      currentPage: this.currentPage,
      pageSize: this.perPage,
      searchQuery: this.searchQuery,
    }

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME_SERVICE}/get`, {
        currentPage: 0,
        pageSize: 0,
        searchQuery: '',
      })
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_SERVICE)) store.unregisterModule(STORE_MODULE_NAME_SERVICE)
  },
  setup() {
    return { STORE_MODULE_NAME, STORE_MODULE_NAME_SERVICE }
  },
  methods: {
    selectServiceType(value, position) {
      if (position === 0) {
        this.selected1 = value
        this.selected2 = null
        this.selected3 = null
      } else if (position === 1) {
        this.selected2 = value
        this.selected3 = null
      } else if (position === 2) {
        this.selected3 = value
      }
    },
    activeList3(value) {
      if (this.selected3) {
        if (this.selected3._id === value._id) {
          return true
        }
        return false
      }
      return false
    },
    activeList2(value) {
      if (this.selected2) {
        if (this.selected2._id === value._id) {
          return true
        }
        return false
      }
      return false
    },
    activeList1(value) {
      if (this.selected1) {
        if (this.selected1._id === value._id) {
          return true
        }
        return false
      }
      return false
    },
    getAgency() {
      const obj = {
        currentPage: 0,
        pageSize: 20,
        searchQuery: '',
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_AGENCY}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    getDepartment() {
      const obj = {
        currentPage: 0,
        pageSize: 20,
        searchQuery: '',
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_DEPARTMENT}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    openMenuSort() {
      this.$router.push({ path: '/hero/manage/service/type/sort' })
    },
    switchChange(name, status, id) {
      console.log(name, status)
      const obj = {
        id,
        name,
        status,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    typing(value) {
      console.log(value)
      this.searchQuery = value
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    sidebarAddNew(selectedPosition) {
      this.sidebarData = {}
      if (selectedPosition === 0) {
        this.isSelectedServiceType = null
      } else if (selectedPosition === 1) {
        this.isSelectedServiceType = this.selected1
      } else if (selectedPosition === 2) {
        this.isSelectedServiceType = this.selected2
      }
      this.isSidebarActive = true
    },
    sidebarEdit(item, selectedPosition) {
      this.sidebarData = item
      if (selectedPosition === 0) {
        this.isSelectedServiceType = null
      } else if (selectedPosition === 1) {
        this.isSelectedServiceType = this.selected1
      } else if (selectedPosition === 2) {
        this.isSelectedServiceType = this.selected2
      }
      this.isSidebarActive = true
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, id)
              .then(result => {
                this.show = false
                console.log('fetch Success : ', result)
              }).catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    nextPage(page) {
      this.currentPage = page
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
